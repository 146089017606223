export  const info =  {

    path : '/info',
    component : () => import('../views/info/indexView'),

    children : [

        {
            path : 'intro',
            component : () => import('../views/info/introView')
        },

        {
            path : 'history',
            component : () => import('../views/info/historyView')
        },

        {
            path : 'location',
            component : () => import('../views/info/locationView')
        },

    ]

};