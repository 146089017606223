const cookie = require('vue-cookies');
cookie.config('7d');

export default {
    FETCH_MAIN_BANNER_MOBILE(state, data) {
        state.MAIN_BANNER_MOBILE = data
    },

    FETCH_MAIN_BANNER_WEB(state, data) {
        state.MAIN_BANNER_WEB = data
    },

    FETCH_SUB_BANNER_WEB(state, data) {
        state.SUB_BANNER_WEB = data
    },

    FETCH_SUB_BANNER_MOBILE(state, data) {
        state.SUB_BANNER_MOBILE = data
    },

    FETCH_CLASS_BANNER_SPECIAL(state, data) {
        state.CLASS_BANNER_SPECIAL = data
    },

    FETCH_CLASS_BANNER_CERTIFICATE(state, data) {
        state.CLASS_BANNER_CERTIFICATE = data
    },

    FETCH_CLASS_LIST(state, data) {
        state.CLASS_LIST = data
    },

    FETCH_NOTICE_LIST(state, data) {
        state.NOTICE_LIST = data
    },

    SET_NOTICE_POST(state, data) {
        state.NOTICE_POST = data
    },

    SET_NOTICE_LIST(state, data) {
        state.NOTICE_LIST = data.noticeList
        state.NOTICE_LIST_TOTAL = data.noticeListTotal
    },

    SET_JOB_LIST(state, data) {
        state.JOB_LIST = data.jobList
        state.JOB_LIST_TOTAL = data.jobListTotal
    },

    SET_RECRUIT_LIST(state, data) {
        state.RECRUIT_LIST = data.recruitList
        state.RECRUIT_LIST_TOTAL = data.recruitListTotal
    },

    SET_RECRUIT_POST(state, data) {
        state.RECRUIT_POST = data
    },

    SET_POPUP(state, data) {
        let check = cookie.get('popup');
        if(check === 'false') state.POPUP = false;
        if(data != undefined) state.POPUP = data;
    },

    SET_POPUP2(state, data) {	    
let check = cookie.get('popup2');
if(check === 'false') state.POPUP2 = false;
if(data != undefined) state.POPUP2 = data;
    }

}
