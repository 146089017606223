export  const std =  {

    path : '/std',
    component : () => import('../views/std/indexView'),

    children : [

        {
		path : 'intro',
		redirect: '/std/education',
		component : () => import('../views/std/introView')
        },

        {
            path : 'cert-cooking',
            component : () => import('../views/std/certCookingView')
        },

        {
            path : 'cert-others',
            component : () => import('../views/std/certOthersView')
        },
        {
		path: 'education',
		component: () => import('../views/std/educationView')
	} 
    ]

};
