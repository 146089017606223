import { createApp } from 'vue';
import App from './App.vue';
import Router from './router';
import Store from './store';
import VueCookies from 'vue-cookies';

import './style/index.css';

createApp(App)
.use(Router)
.use(Store)
.use(VueCookies, {
    expireTimes: '1d',
})
.mount('#app')
