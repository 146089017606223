export  const gov =  {

    path : '/gov',
    component : () => import('../views/gov/indexView'),

    children : [

        {
            path : 'intro',
            component : () => import('../views/gov/introView')
        },

        {
            path : 'naeil-baeum-card',
            component : () => import('../views/gov/applyCardView')
        },

        {
            path : 'cert-cooking',
            component : () => import('../views/gov/certCookingView')
        },

        {
            path : 'cert-baking',
            component : () => import('../views/gov/certBakingView')
        },

        {
            path : 'cert-barista',
            component : () => import('../views/gov/certBaristaView')
        },

    ]

};
