<template>
  <div>
    <top-navigation/>
    <bottom-navigation/>
    <router-view/>
  </div>
</template>

<script>
import TopNavigation from './components/common/TopNavigation';
import BottomNavigation from './components/common/BottomNavigation';

export default {
  name: 'App',
  
  components : {
    TopNavigation,
    BottomNavigation
  }

}
</script>
