import { createRouter, createWebHistory } from 'vue-router';
import { info } from './info'
import { gov } from './gov'
import { std } from './std'
import { comm } from './comm'

const routes = [
    {
        path : '/',
        component : () => import('../views/mainView'),
    },

    info, gov, std, comm
    
]

const router = createRouter({
    history : createWebHistory(process.env.BASE_URL),
    routes
})

export default router
