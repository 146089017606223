<template>
  <div class="bottom-nav-container">
      <span class="bottom-nav-item info-area center column">
        <a href="/info/intro">
          <img :src="infoIcon"/>
          <p>학교소개</p>
        </a>
      </span>
      <span class="bottom-nav-item gov-cert-area center column">
        <a href="/gov/intro">
        <img :src="govCertIcon"/>
        <p>국비교육</p>
        </a>
      </span>

      <span class="bottom-nav-item std-cert-area center column">
        <a href="/std/education">
        <img :src="stdCertIcon"/>
        <p>고교위탁</p>
        </a>
      </span>

      <span class="bottom-empty-item">
      </span>
  </div>
</template>

<script>
import govCertIcon from '../../assets/icons/gov_cert_icon.svg'
import stdCertIcon from '../../assets/icons/std_cert_icon.svg'
import infoIcon from '../../assets/icons/info_icon.svg'

export default {
    data() {
        return {
            govCertIcon,
            stdCertIcon,
            infoIcon,
        }
    }
}
</script>

<style>

</style>
