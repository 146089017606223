import axios from 'axios';

export default {

    async FETCH_MAIN(context) {

        if(!isNaN(context.state.mainBannerMobile)) return
        if(!isNaN(context.state.mainBannerWeb)) return
        if(!isNaN(context.state.subBannerWeb)) return
        if(!isNaN(context.state.subBannerMobile)) return
        if(!isNaN(context.state.classBannerSpecial)) return
        if(!isNaN(context.state.classBannerCertificate)) return
        if(!isNaN(context.state.classList)) return
        if(!isNaN(context.state.noticeList)) return

        const result = await axios.get('/api/fetch-main')

        context.commit('FETCH_MAIN_BANNER_MOBILE', result.data.mainBannerMobile)
        context.commit('FETCH_MAIN_BANNER_WEB', result.data.mainBannerWeb)
        context.commit('FETCH_SUB_BANNER_WEB', result.data.subBannerWeb)
        context.commit('FETCH_SUB_BANNER_MOBILE', result.data.subBannerMobile)
        context.commit('FETCH_CLASS_BANNER_SPECIAL', result.data.classBannerSpecial)
        context.commit('FETCH_CLASS_BANNER_CERTIFICATE', result.data.classBannerCertificate)
        context.commit('FETCH_CLASS_LIST', result.data.classList)
        context.commit('FETCH_NOTICE_LIST', result.data.noticeList)
    },

    async FETCH_NOTICE_POST(context, payload) {
        const data = {
            id : payload
        }
        const result = await axios.post('/api/fetch-notice-post', data)
        context.commit('SET_NOTICE_POST', result.data.noticePost)
    },

    async FETCH_NOTICE_LIST(context, payload) {
        const data = {
            offset : payload
        }
        const result = await axios.post('/api/fetch-notice-list', data)
        context.commit('SET_NOTICE_LIST', result.data)
    },

    async FETCH_JOB_LIST(context, payload) {
        const data = {
            offset : payload
        }
        const result = await axios.post('/api/fetch-job-list', data)
        context.commit('SET_JOB_LIST', result.data)
    },

    async FETCH_RECRUIT_POST(context, payload) {
        const data = {
            id : payload
        }
        const result = await axios.post('/api/fetch-recruit-post', data)
        context.commit('SET_RECRUIT_POST', result.data.recruitPost)
    },

    async FETCH_RECRUIT_LIST(context, payload) {
        const data = {
            offset : payload
        }
        const result = await axios.post('/api/fetch-recruit-list', data)
        context.commit('SET_RECRUIT_LIST', result.data)
    },

}
