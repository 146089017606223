<template>
    <div class="nav-container center" :class="backgroundClass"
        @mouseenter="onShadow()"
        @mouseleave="onTranspaernt()">

        <div class="nav row space-between">

            <span class="nav-logo row cursor"
            @click="onMoveMain()">
                <img class="nav-logo-image default-margin" :src="logo" />
                <p class="nav-logo-text">한선생직업전문학교</p>
            </span>

            <div class="nav-content row" >
                <!-- nav contents v-for -->
		
		<span class="nav-content-list default-margin-2" style="display:flex;">
			<a href="https://blog.naver.com/2558586" target="_blank" style="margin-left: 12px; display:flex;">
			<img :src="blogIcon" style="width: 32px;"/>
			</a>
<a href="https://www.instagram.com/han_educook_real/" target="_blank" style="margin-left: 12px; display:flex;">			
<img :src="instaIcon" style="width: 32px;"/>
</a>
<a href="https://pf.kakao.com/_MDgaK" style="margin-left: 12px; display:flex;">	
<img :src="kakaoIcon" style="width: 32px;"/>
</a>			
		</span>

                <span class="nav-content-list default-margin-2"
                v-for="(navItem, navIndex) in nav"
                :key="'nav-'+navIndex">
                    <p class="cursor">{{navItem.title}}</p>
                    <ul class="sub-nav-content-list">
                        <!-- sub nav contents v-for -->
                        <a class="sub-nav-content-link"
                        v-for="(subNavItem, subNavIndex) in navItem.subNav"
                        :key="'sub-nav-'+subNavIndex"
                        :href="(subNavItem.link? subNavItem.link : subNavItem.outerLink)">
                            <li class="sub-nav-content-item cursor">{{subNavItem.title}}</li>
                        </a>
                    </ul>
                </span>
		<span>  
		<!-- <a href="https://blog.naver.com/2558586" style="margin-left: 12px;" target="_blank">블로그</a> -->
		</span>
            </div>

        </div>
    </div>
</template>

<script>
import logo from '../../assets/images/academy_logo.png'
import { nav } from '../../data/nav'
import kakaoIcon from '../../assets/icons/kakao.png';
import instaIcon from '../../assets/icons/insta.png';
import blogIcon from '../../assets/icons/blog.png';

export default {
    created() {
        window.addEventListener('scroll', this.onScroll)
        // ? 다른 화면에서 무조건 shadow class 가지도록 설정
        if(this.$route.path!='/main') this.backgroundClass = 'shadow'
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },
// ! fetch state navigation
    data () {
        return {
            logo, nav, kakaoIcon, instaIcon, blogIcon,
            // ? background 초기 투명값 유지
            backgroundClass : 'transparent',
            scrollY : 0,
        }
    },
    methods : {
        // ? if scroll down, convert transparent to shadow style
        onScroll() {
            if(this.$route.path!='/main') return
            this.scrollY = window.pageYOffset;
            if(this.scrollY >= 20) this.backgroundClass = 'shadow'
            else this.backgroundClass = 'transparent'
        },
        onTranspaernt() {
            // ? 다른 화면에서 무조건 shadow class 가지도록 설정
            if(this.$route.path!='/main') return
            // ? scroll down 상태에서 mouse leave 시, transparent 되는 증상 해결
            if(this.scrollY >= 20) return
            this.backgroundClass = 'transparent'
        },
        onShadow() {
            this.backgroundClass = 'shadow'
        },
        onMoveMain() {
            this.$router.push('/')
        }
    },
}
</script>

<style>
</style>
