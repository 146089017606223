export default {
    MAIN_BANNER_MOBILE : [],
    MAIN_BANNER_WEB : [],
    SUB_BANNER_WEB : [],
    SUB_BANNER_MOBILE : [],
    CLASS_BANNER_SPECIAL : [],
    CLASS_BANNER_CERTIFICATE : [],
    CLASS_LIST : [],
    NOTICE_LIST : [],
    NOTICE_LIST_TOTAL : 0,
    NOTICE_POST : [],
    JOB_LIST : [],
    JOB_LIST_TOTAL : 0,
    RECRUIT_LIST : [],
    RECRUIT_LIST_TOTAL : 0,
    RECRUIT_POST : [],
    POPUP: true,
    POPUP2: true,
}
