export const nav = [

    {title : '학교소개', 
        subNav : [
            {title : '인사말', link : '/info/intro'},
            {title : '학교연혁', link : '/info/history'},
            {title : '오시는 길', link : '/info/location'}
        ]
    },

    {title : '국비교육', 
        subNav : [
            {title : '국비교육 신청방법', link : '/gov/intro'},
            {title : '국민내일배움카드', link : '/gov/naeil-baeum-card'},
            {title : '요리', link : '/gov/cert-cooking'},
            {title : '제과제빵', link : '/gov/cert-baking'},
            {title : '바리스타', link : '/gov/cert-barista'},
        ]
    },

    {title : '고교위탁', 
        subNav : [
            {title : '일반고특화 직업훈련', link : '/std/education'},
            //{title : '한식/중식/양식', link : '/std/cert-cooking'},
            //{title : '제과제빵/바리스타', link : '/std/cert-others'},
        ]
    },

    {title : '커뮤니티', 
        subNav : [
            {title : '공지사항', link : '/comm/notice'},
            {title : '취업현황', link : '/comm/job'},
            {title : '구인구직', link : '/comm/recruit'},
/*            {title : '갤러리', link : '/comm/gallery'}, */
/*            {title : '블로그', outerLink : 'https://blog.naver.com/2558586'}, */
        ]
    },


]

export const baseUrl = '/main'
