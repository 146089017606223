export  const comm =  {

    path : '/comm',
    component : () => import('../views/comm/indexView'),

    children : [

        {
            path : 'notice',
            component : () => import('../views/comm/noticeView')
        },

        {
            path : '/comm/notice/:id',
            component : () => import('../views/comm/noticePostView')
        },

        {
            path : 'job',
            component : () => import('../views/comm/jobView')
        },

        {
            path : 'recruit',
            component : () => import('../views/comm/recruitView')
        },

        {
            path : '/comm/recruit/:id',
            component : () => import('../views/comm/recruitPostView')
        },
        
/**
        {
            path : 'gallery',
            component : () => import('../views/comm/galleryView')
        }, */
        
    ]

    
};